// JavaScript 파일에서 SweetAlert2 불러오기
import Swal from 'sweetalert2'

const fncShowSwal = (type, Messages = null) => {
  const config = {
    confirm: {
      title: '변경내용을 저장하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      background: 'rgb(3,11,37)',
      customClass: {
        cancelButton: 'btn btn-dark',
        confirmButton: 'btn btn-primary mr-1',
      },
    },
    delete: {
      title: '삭제하시겠습니까?<br>삭제하시면 복구할 수 없습니다!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      background: 'rgb(3,11,37)',
      customClass: {
        cancelButton: 'btn btn-dark',
        confirmButton: 'btn btn-primary mr-1',
      },
    },
    success: {
      title: '저장 완료',
      icon: 'success',
      timer: 5000,
      background: 'rgb(3,11,37)',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    },
    error: {
      title: '저장 실패',
      icon: 'error',
      text: '시스템 관리자에게 문의바랍니다.',
      timer: 5000,
      background: 'rgb(3,11,37)',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    },

    // Messages
    send: {
      title: '메세지를 전송하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      background: 'rgb(3,11,37)',
      customClass: {
        cancelButton: 'btn btn-dark',
        confirmButton: 'btn btn-primary mr-1',
      },
    },
    recall: {
      title: '메세지를 회수하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      background: 'rgb(3,11,37)',
      customClass: {
        cancelButton: 'btn btn-dark',
        confirmButton: 'btn btn-primary mr-1',
      },
    },
  }[type]

  // 추가적으로 전달된 message가 있다면 해당 설정을 덮어씁니다.
  if (Messages) config.title = Messages

  // Swal을 실행합니다.
  return Swal.fire(config)
}

export default fncShowSwal
